import {API_BASE_URL} from '@/config/config'
import axios from '@/utils/axios'

//登陆
export const signInUser = (params) => axios.post('bi/auth/signInUser', params)
//获取滑块验证码
export const  getSliderVerify = (params) => axios.get('bi/auth/getSliderVerify',{params})
//一次校验滑块验证码
export const  sliderVerifyCheck = (params) => axios.get('bi/auth/sliderVerifyCheck',{params})

//退出登录
export const loginOut = (params) => axios.get('bi/auth/loginOut', {params})
//获取用户信息
export const getAdminInfo = (params) => axios.get('bi/auth/getAdminInfo', {params})
// 概览
export const overview = (params) => axios.get('bi/index/overview', {params})
// 入驻商家增长趋势
export const merchantGrowingTrends = (params) => axios.get('bi/index/merchantGrowingTrends', {params})
// 近三个月交易数据
export const lastThreeMonthsPaidData = (params) => axios.get('bi/index/lastThreeMonthsPaidData', {params})
// 近六月签约趋势
export const lastSixMonthsNewLeaseTrends = (params) => axios.get('bi/index/lastSixMonthsNewLeaseTrends', {params})
// 商家地区分布top5
export const merchantRegionDistributionTop5 = (params) => axios.get('bi/index/merchantRegionDistributionTop5', {params})
// 设备告警列表
export const deviceAbnormalList = (params) => axios.get('bi/index/deviceAbnormalList', {params})
export const deviceAbnormalListUrl = 'bi/index/deviceAbnormalList'
// 获取近六月能耗统计
export const getEnergyConsumptionStatistics = (params) => axios.get('bi/index/getEnergyConsumptionStatistics', {params})
